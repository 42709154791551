exports.components = {
  "component---src-templates-pages-404-page-js": () => import("./../../../src/templates/pages/404-page.js" /* webpackChunkName: "component---src-templates-pages-404-page-js" */),
  "component---src-templates-pages-about-us-page-js": () => import("./../../../src/templates/pages/about-us-page.js" /* webpackChunkName: "component---src-templates-pages-about-us-page-js" */),
  "component---src-templates-pages-advice-details-page-js": () => import("./../../../src/templates/pages/advice-details-page.js" /* webpackChunkName: "component---src-templates-pages-advice-details-page-js" */),
  "component---src-templates-pages-advices-listing-page-js": () => import("./../../../src/templates/pages/advices-listing-page.js" /* webpackChunkName: "component---src-templates-pages-advices-listing-page-js" */),
  "component---src-templates-pages-blog-details-page-js": () => import("./../../../src/templates/pages/blog-details-page.js" /* webpackChunkName: "component---src-templates-pages-blog-details-page-js" */),
  "component---src-templates-pages-blogs-listing-page-js": () => import("./../../../src/templates/pages/blogs-listing-page.js" /* webpackChunkName: "component---src-templates-pages-blogs-listing-page-js" */),
  "component---src-templates-pages-condition-details-page-js": () => import("./../../../src/templates/pages/condition-details-page.js" /* webpackChunkName: "component---src-templates-pages-condition-details-page-js" */),
  "component---src-templates-pages-conditions-listing-page-js": () => import("./../../../src/templates/pages/conditions-listing-page.js" /* webpackChunkName: "component---src-templates-pages-conditions-listing-page-js" */),
  "component---src-templates-pages-home-page-js": () => import("./../../../src/templates/pages/home-page.js" /* webpackChunkName: "component---src-templates-pages-home-page-js" */),
  "component---src-templates-pages-testing-page-js": () => import("./../../../src/templates/pages/testing-page.js" /* webpackChunkName: "component---src-templates-pages-testing-page-js" */)
}

